import {getNamesFromArray} from "@/utils/formData";

export default {
  countCells: 6,
  massive: [
    {
      dataField: 'olympiad',
      cols: 2,
      rows: 1,
      text: 'Олимпиада',
      class: 'd-flex flex-row align-start justify-start ',
      headerClass: 'd-flex flex-row justify-space-between',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      headerTooltip: true,
      headerTooltipText: () => 'Полное наименование олимпиады в соответствии с утвержденным положением об олимпиаде или при его отсутствии в соответствии с проектом положения об олимпиаде',
      order: 0,
      variable: true,
    },
    {
      dataField: 'value',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Общая потребность в количестве мест на 2024/25 учебный год по олимпиаде',
    },
    {
      dataField: 'profiles',
      cols: 2,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: ' ',
      class: 'px-2 align-start',
      headerStyles: `min-width: 200px`,
      text: `Потребность в количестве мест на 2024/25 учебный год по профилям`,
      template: (r)=> getNamesFromArray(r?.profiles,['name', 'value'], '<br/>')
    },
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: 'Редактировать',
    },

  ]
}
